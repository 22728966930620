#title {
    text-align: center;
    font-family: arial, sans-serif;
}

.news {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    border: 3px solid #ddd;
    width: 100%;
    font-size: 12px;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
}

.news td {
    padding: 10px;
}

.news th {
    /* border: 1px solid #ddd; */
    padding: 8px;
}

.news th:nth-child(1),
.news th:nth-child(2),
.news th:nth-child(3),
.news td:nth-child(1),
.news td:nth-child(2),
.news td:nth-child(3) {
    text-align: center !important;
}

.news th:last-child {
    width: 80%
}

.news tr:nth-child(even) {
    background-color: #f2f2f2;
}

.news th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left !important;
    background-color: #f46326;
    color: white;
}

.news-detail {
    line-height: 2em;
}

.triangle {
    color: gray;
    cursor: pointer;
}

.triangle:hover {
    color: #f46326;
}

.voted {
    color: #f46326;
}

.site {
    color: gray;
}

.hide,
.site,
.author,
.paginateButtons {
    margin-right: 5px;
}

.title {
    font-size: 15px;
    margin-right: 10px;
    font-family: inherit;
}

.hide {
    cursor: pointer;
}

.hide::after {
    content: " ]";
    color: gray;
}

.hide::before {
    content: "[ ";
    color: gray;
}

.paginateButtons {
    float: right;
    color: #f46326;
    font-size: 15px;
}

.previous,
.next {
    cursor: pointer;
    font-weight: bold;
    color: #f46326;
}

.previous:hover,
.next:hover {
    text-decoration: underline;
}

.page-num {
    float: right;
    margin-right: 5px;
}

.bookmark {
    margin-left: 5px;
    cursor: pointer;
}

.isDisabled {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
    text-decoration: none;
}

.hidden {
    background-color: #8e89d9 !important;
}

@media only screen and (max-width:800px) {

    /* For tablets: */
    .news {
        width: 80%;
        padding: 0;
        /* overflow: hidden; */
        overflow-x: hidden;
    }
}

@media only screen and (max-width:500px) {

    /* For mobile phones: */
    .news {
        width: 90%;
        overflow-x: hidden;
    }
}